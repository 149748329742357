export function swiper() {
  const slider01 = document.querySelectorAll('.js-slider01');
  if (slider01.length > 0) {
    slider01.forEach((slider) => {
      const slider_el = slider.querySelector('.swiper');
      const swiper = new Swiper(slider_el, {
        slidesPerView: 1,
        slideActiveClass: 'is-active',
        watchSlidesProgress: true,
        slideVisibleClass: 'is-visible',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoHeight: true,

        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
            autoHeight: false,
          },
          1001: {
            slidesPerView: 2,
            spaceBetween: 60,
            autoHeight: false,
          },
        },
      });
    });
  }
}

export function swiperNaviOnly() {
  const swiperNaviOnlyAll = document.querySelectorAll('.js-swiperNaviOnly');

  if (swiperNaviOnlyAll.length > 0) {
    const swiperNaviOnlyFunc = new Swiper('.js-swiperNaviOnly', {
      spaceBetween: 0,
      loop: true,
      navigation: {
        nextEl: '.js-swiperNaviOnlyNext',
        prevEl: '.js-swiperNaviOnlyPrev',
      },
    });
  }
}
