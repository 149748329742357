export function mvSlide() {
  const mvSlide = document.querySelector('.js-mvSlide');

  if(mvSlide) {
    const mySwiper = new Swiper(mvSlide, {
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      loop: true,
      loopAdditionalSlides: 1,
      speed: 500,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
        waitForTransition: false,
      },
      followFinger: false,
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
      }
    });
  }
}