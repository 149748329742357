export function header() {
  const header = document.querySelector('.js-header');
  let lastScrollTop = 0;

  const scrollAction = () => {
    const scrollTop = window.scrollY;
    if(scrollTop > lastScrollTop) {
      header.classList.add('is-scrolled');
    } else {
      header.classList.remove('is-scrolled');
    }

    lastScrollTop = scrollTop;
  }

  // デバウンス関数、scrollActionが呼び出される頻度を制限
  const debounce = (func, wait) => {
    let timeout;
    return function(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  window.addEventListener('load', scrollAction);
  window.addEventListener('scroll', debounce(scrollAction, 10));  // デバウンスの遅延を適宜調整
}