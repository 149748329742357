export function prayers() {
  const prayersModal = document.querySelector('.js-prayersModal');

  const modalOpen = (el01, el02) => {
    el02.classList.add('is-view');

    const modalId = el01.dataset.modal;
    const modalCont = document.getElementById(modalId);
    modalCont.classList.add('is-active');
  }

  const modalClose = (array01, el, array02) => {
    array01.forEach(element => {
      element.addEventListener('click', () => {
        el.classList.remove('is-view');

        array02.forEach(element => {
          element.classList.remove('is-active');
        });
      })
    });
  }

  const modal = () => {
    const triggers = document.querySelectorAll('.js-prayersModal-trigger');
    const panel = document.querySelector('.js-prayersModal-panel');
    const closes = document.querySelectorAll('.js-prayersModal-close');
    const contents = document.querySelectorAll('.js-prayersModal-cont');

    triggers.forEach(trigger => {
      trigger.addEventListener('click', function() {
        modalOpen(this, panel);
      });
    });

    modalClose(closes, panel, contents);
  }

  if(prayersModal) {
    modal();
  }
}