import { header } from './modules/header';
import { mvSlide } from './modules/mvSlide';
import { pagetop } from './modules/pagetop';
import { smooth } from './modules/smooth';
import { hamburger } from './modules/hamburger';
import { accordion } from './modules/accordion';
import { scrollmagic } from './modules/scrollmagic';
import { modal } from './modules/modal';
import { swiper, swiperNaviOnly } from './modules/swiper';
import { event } from './modules/event';
import { prayers } from './modules/prayers';

window.addEventListener('load', () => {
  //scrollに応じてアニメーションしたい時
  scrollmagic();

  //scrollによる処理を間引きしたい時
  // scrollAction();
});

header();
mvSlide();
pagetop();
smooth();
hamburger();
accordion();
modal();
swiper();
swiperNaviOnly();
event();
prayers();
