export function event() {
  const eventEl = document.querySelector('#event');

  if (eventEl) {
    let dataId;
    const eventConts = eventEl.querySelectorAll('.js-eventCont');
    const eventItems = eventEl.querySelectorAll('.js-eventItem');
    window.addEventListener('scroll', () => {
      const windowHeight = window.innerHeight;
      const scrollTop = window.pageYOffset;
  
      eventConts.forEach(el => {
        const rect = el.getBoundingClientRect();
        const elementTop = rect.top + scrollTop;
        const elementBottom = elementTop + rect.height;
  
        // 要素のトップがブラウザの中央を通過したかどうかを判定
        if (elementTop < scrollTop + windowHeight / 2 && elementBottom > scrollTop + windowHeight / 2) {
          // dataIdに要素のIDを設定
          dataId = el.id;
        }
      });

      eventItems.forEach(item => {
        const itemId = item.dataset.id;
        if(itemId === dataId) {
          item.classList.add('is-current');
        } else {
          item.classList.remove('is-current');
        }
      });
    });

    //SP
    const eventLinkBtns = document.querySelectorAll('.js-eventLinkBtn');
    const eventLinkCont = document.querySelector('.js-eventLinkCont');
    const slideUp = (el, duration = 300) => {
      el.style.height = el.offsetHeight + "px";
      el.offsetHeight;
      el.style.transitionProperty = "height, margin, padding";
      el.style.transitionDuration = duration + "ms";
      el.style.transitionTimingFunction = "ease";
      el.style.overflow = "hidden";
      el.style.height = 0;
      el.style.paddingTop = 0;
      el.style.paddingBottom = 0;
      el.style.marginTop = 0;
      el.style.marginBottom = 0;
      setTimeout(() => {
        el.style.display = "none";
        el.style.removeProperty("height");
        el.style.removeProperty("padding-top");
        el.style.removeProperty("padding-bottom");
        el.style.removeProperty("margin-top");
        el.style.removeProperty("margin-bottom");
        el.style.removeProperty("overflow");
        el.style.removeProperty("transition-duration");
        el.style.removeProperty("transition-property");
        el.style.removeProperty("transition-timing-function");
        el.classList.remove("is-open");
      }, duration);
    };
    eventLinkBtns.forEach(eventLinkBtn => {
      eventLinkBtn.addEventListener('click', () => {
        slideUp(eventLinkCont);
      });
    });

    const pagetopTarget = document.querySelector('.js-followTarget');
    const eventLink = document.querySelector('.js-eventLink');
    window.addEventListener('scroll', () => {
      const pagetopTarget_y = pagetopTarget.getBoundingClientRect().top + window.pageYOffset;
      const scoroll_y = window.scrollY + window.innerHeight;
      if(scoroll_y > pagetopTarget_y) {
        eventLink.classList.add('is-static');
      } else {
        eventLink.classList.remove('is-static');
      }

      if(window.scrollY > 0) {
        eventLink.classList.add('is-view');
      } else {
        eventLink.classList.remove('is-view');
      }
    });
  }
}