export function pagetop() {
  const pagetop = document.querySelector('.js-pagetop');

  //ページトップボタン表示
  const isView = () => {
    let scroll_y = window.scrollY;
    if(scroll_y > 0) {
      pagetop.classList.add('is-view');
    } else {
      pagetop.classList.remove('is-view');
    }
  };

  //特定の要素の上で追従を止める
  const footer = document.querySelector('.l-footer');
  const pagetopTarget = document.querySelector('.js-pagetopTarget');
  const target = pagetopTarget ? pagetopTarget : footer;
  const Y_PC = 40;
  const Y_SP = 20;
  const followStop = () => {
    const targetY = target.getBoundingClientRect().top + window.scrollY;
    const scrollY = window.scrollY;
    const windowH = window.innerHeight;

    if( scrollY > targetY - windowH) {
      const pagetop_h = pagetop.offsetHeight;
      const offsetY = window.matchMedia('(max-width: 767px)').matches ? Y_SP : Y_PC;
      const top = targetY - pagetop_h - offsetY;
      pagetop.classList.add('is-fixed');
      pagetop.style.top = `${top}px`;
      //ここに記述
    } else {
      pagetop.classList.remove('is-fixed');
        pagetop.style.top = '';
    }
  }

  window.addEventListener('load', () => {
    isView();
    followStop();
  });

  window.addEventListener('scroll', () => {
    isView();
    followStop();
  });
}