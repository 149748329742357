export function modal() {
  const modals = document.querySelectorAll('.js-modal');

  if(modals.length) {
    const openModal = (el) => {
      el.classList.add('is-view');
    }

    const closeModal = (el) => {
      el.classList.remove('is-view');
    }

    modals.forEach(modal => {
      const modalTrigger = modal.querySelector('.js-modalTrigger');
      const modalContent = modal.querySelector('.js-modalContent');
      modalTrigger.addEventListener('click', () => {
        openModal(modalContent);
      });

      const modalCloses = modal.querySelectorAll('.js-modalClose');
      modalCloses.forEach(modalClose => {
        modalClose.addEventListener('click', () => {
          closeModal(modalContent);
        });
      });
    });
  }
}